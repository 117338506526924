import Axios from '@/axios';
import ApendayChat from '@/classes/apenday-chat';
import ApendayVideo from '@/classes/apenday-video';
import { find } from 'lodash';
import { computed, readonly, ref, Ref, shallowReactive, shallowRef } from 'vue';
import { useStore } from 'vuex';

export const interactionsPool = shallowReactive<{ [key in string]: ApendayChat | ApendayVideo }>({});
const activeInteraction = shallowRef<Interaction | Session>();

export const activeInteractionRead = readonly(activeInteraction);
export const updateActiveInteraction = (interaction: Interaction | Session | null) => {
    activeInteraction.value = interaction;
};

export const flushInteraction = async (interaction: Interaction | Session) => {
    if (interactionsPool[interaction.id]) {
        await interactionsPool[interaction.id].shutdown();
        delete interactionsPool[interaction.id];
    }
};

export const flushAllInteractions = async () => {
    for (const key in interactionsPool) {
        await interactionsPool[key].shutdown();
        delete interactionsPool[key];
    }
};

export const useInteraction = (interaction: Ref<Interaction | Session>) => {
    // @ts-ignore
    const isInteraction = !!interaction.value.widget;
    const isFetching = ref<boolean>(false);
    const store = import.meta.env.VITE_APP === 'admin' ? useStore() : null;
    const me = computed<User>(() => store?.state.user.me);
    const userTwilioToken = computed(() => {
        if (import.meta.env.VITE_APP !== 'admin') {
            return interaction.value.user_twilio_token;
        }
        // Admin app
        let userToken = '';
        const areaId = isInteraction ? interaction.value.widget.area.id : interaction.value.area.id;
        const twilioToken = find(me.value.twilio_tokens, (o) => o.area === areaId);
        if (twilioToken) userToken = twilioToken.token;

        const meIsConsultant = interaction.value.consultant?.['@id'] === me.value['@id'];
        const spyMode = interaction.value.status === 'closed' || (interaction.value.status === 'in_progress' && !meIsConsultant);

        return spyMode ? interaction.value.user_twilio_token : userToken;
    });
    const userNickname = computed(() => {
        return import.meta.env.VITE_APP === 'admin' ? me.value?.full_name : interaction.value.user_identity;
    });
    const userEmail = computed(() => {
        return import.meta.env.VITE_APP === 'admin' ? me.value?.email : interaction.value.user_identity;
    });

    const addUser = async (user: User): Promise<Interaction> => {
        isFetching.value = true;
        const response = await Axios.put<Interaction, Interaction>('/api/private/live/interactions/' + interaction.value.id, {
            consultant: user['@id'],
        });
        isFetching.value = false;

        return response;
    };

    const close = async (): Promise<Interaction | Session> => {
        isFetching.value = true;
        const url = isInteraction ? '/api/private/live/interactions/' : '/api/private/assist/sessions/';
        const response = await Axios.put<Interaction | Session, Interaction | Session>(url + interaction.value.id, {
            status: 'closed',
        });
        await shutdownInstance();
        isFetching.value = false;

        return response;
    };

    const shutdownInstance = async () => {
        if (interactionsPool[interaction.value.id]) {
            await interactionsPool[interaction.value.id].shutdown();
            delete interactionsPool[interaction.value.id];
        }
    };

    const getInstance = (): ApendayChat | ApendayVideo => {
        if (!interactionsPool[interaction.value.id]) {
            let facingMode: 'environment' | 'user' = 'user';
            if (import.meta.env.VITE_APP === 'assist') {
                if (interaction.value.area?.assist_config?.default_camera === 'back') {
                    facingMode = 'environment';
                } else {
                    facingMode = 'user';
                }
            }
            const config: InteractionInstanceConfig = {
                interaction: interaction.value,
                userTwilioToken: userTwilioToken.value,
                userNickname: userNickname.value,
                userEmail: userEmail.value,
                facingMode,
            };

            interactionsPool[interaction.value.id] =
                interaction.value.channel === 'messaging' ? new ApendayChat(config) : new ApendayVideo(config);
        }

        return interactionsPool[interaction.value.id];
    };

    const updateTwilioToken = async () => {
        if (import.meta.env.VITE_APP !== 'admin') return;

        await store.dispatch('user/getMe');
        interactionsPool[interaction.value.id].updateToken(userTwilioToken.value);
    };

    return {
        addUser,
        close,
        getInstance,
        shutdownInstance,
        isFetching,
        updateTwilioToken,
    };
};
