import Toastify from 'toastify-js/src/toastify-es.js';

type ToastOptions = {
    text: string;
    type: 'error' | 'success';
};

export const toast = async ({ text, type }: ToastOptions) => {
    Toastify({
        text,
        gravity: 'bottom',
        position: 'center',
        stopOnFocus: true,
        duration: 4000,
        style: {
            background:
                type === 'success'
                    ? 'linear-gradient(to right, var(--ion-color-success-shade), var(--ion-color-success)'
                    : 'linear-gradient(to right, var(--ion-color-danger-shade), var(--ion-color-danger))',
        },
    }).showToast();
};
