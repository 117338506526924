import { isNil } from 'lodash';
import { addMethod, setLocale, string } from 'yup';

setLocale({
    mixed: {
        required: 'validation.required',
        oneOf: ({ values }) => ({ key: 'validation.oneOf', values: { values } }),
    },
    string: {
        email: 'validation.email',
        url: 'validation.url',
        max: ({ max }) => ({ key: 'validation.stringMax', values: max }),
        length: ({ length }) => ({ key: 'validation.stringLength', values: length }),
    },
    array: {
        min: ({ min }) => ({ key: 'validation.min', values: min }),
    },
    number: {
        min: ({ min }) => ({ key: 'validation.min', values: min }),
    },
});

const phoneRegExp = new RegExp('^0([1-9])([-\\.\\s]?)([0-9]{2})([-\\.\\s]?)([0-9]{2})([-\\.\\s]?)([0-9]{2})([-\\.\\s]?)([0-9]{2})$');

addMethod(string, 'phoneTest', function () {
    return this.test('phoneNumber', 'validation.phoneNumber', (val) => isNil(val) || val.length === 0 || phoneRegExp.test(val));
});
