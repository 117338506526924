import { pollAudioLevel } from '@/classes/pollaudiolevel';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';
import { find, findIndex } from 'lodash';
import { MouseEvent } from 'react';
import type { LocalTrack, RemoteTrack } from 'twilio-video';
import {
    LocalAudioTrack,
    LocalDataTrack,
    LocalVideoTrack,
    RemoteTrackPublication,
    Room,
    connect,
    createLocalAudioTrack,
    createLocalVideoTrack,
    isSupported,
} from 'twilio-video';
import type { RemoteParticipant } from 'twilio-video/tsdef/RemoteParticipant';

export type MediaDeviceKind = 'audioinput' | 'audiooutput' | 'videoinput';
export type PointerData = { x: number; y: number };
export type DevicesIds = {
    speakerDeviceId?: string;
    micDeviceId?: string;
    videoDeviceId?: string;
};
export type ApendayVideoEvents = {
    ready: () => void;
    error: (error: string | Error) => void;
    connected: (enabled: boolean) => void;
    disconnected: () => void;
    participantUIsUpdate: (participantUIs: VideoParticipantUI[]) => void;
    videoActive: (enabled: boolean) => void;
    audioActive: (enabled: boolean) => void;
    screenSharingActive: (enabled: boolean) => void;
    pointerActive: (enabled: boolean) => void;
    audioLevelChange: (level: number) => void;
    recordingStatusChange: (enabled: boolean) => void;
    deviceIdChange: (deviceIds: DevicesIds) => void;
    pointerEnabled: (enabled: boolean) => void;
};

export type FacingMode = 'environment' | 'user';

export interface VideoParticipantUI {
    sid: string;
    identity: string;
    isAudioEnabled: boolean;
    isVideoEnabled: boolean;
}

export default class ApendayVideo extends ReplayEventEmitter<ApendayVideoEvents> {
    interaction: Interaction | Session;
    userTwilioToken: string;
    userNickname: string;
    room: Room | undefined;
    public connected: boolean;
    localVideoTrack: LocalVideoTrack | undefined;
    localAudioTrack: LocalAudioTrack | undefined;
    screenTrack: LocalVideoTrack | undefined;
    dataTrack: LocalDataTrack | undefined;
    deviceIds: DevicesIds = {};
    participantUIs: VideoParticipantUI[] = [];
    needReloadLocalVideo = false;
    facingMode: FacingMode;

    constructor({ userTwilioToken, interaction, userNickname, facingMode }: InteractionInstanceConfig) {
        super();
        if (!userTwilioToken?.length || !interaction || !userNickname?.length) {
            console.error("Can't init ApendayVideo instance, user twilio token, interaction, userNickname missing");
            return;
        }
        this.interaction = interaction;
        this.facingMode = facingMode;
        this.userTwilioToken = userTwilioToken;
        this.userNickname = userNickname;
        this.initClient();
    }

    getVideoContainerClasses(onTop = false) {
        const classes = ['tw-relative'];
        if (onTop) classes.push('tw-z-10');
        return classes;
    }

    initClient() {
        if (!isSupported) {
            this.handleError(new Error('Video is not supported on your device'));
        } else {
            // const logger = Logger.getLogger('twilio-video');
            // logger.setLevel('warn');
            this.emit('ready');
        }
    }

    async createLocalVideoTrack() {
        const div = document.getElementById('local-media-div');
        if (!div) throw new Error('no "local-media-div" ID container found');
        const options = this.deviceIds.videoDeviceId
            ? { deviceId: this.deviceIds.videoDeviceId }
            : {
                  facingMode: this.facingMode,
              };
        this.localVideoTrack = await createLocalVideoTrack({
            ...options,
            ...(import.meta.env.VITE_APP === 'assist' && {
                aspectRatio: 0.5625,
                width: {
                    ideal: 1080,
                },
                height: {
                    ideal: 1920,
                },
            }),
        });
        this.deviceIds.videoDeviceId = this.localVideoTrack.mediaStreamTrack.getSettings().deviceId;
        this.emit('deviceIdChange', this.deviceIds);
        const videoElement = this.localVideoTrack.attach();
        videoElement.classList.add(...this.getVideoContainerClasses());
        div.appendChild(videoElement);
        if (videoElement instanceof HTMLVideoElement) {
            videoElement.addEventListener(
                'loadeddata',
                () => {
                    this.handleVideoSize(videoElement);
                },
                { once: true },
            );
        }
        this.emit('videoActive', true);
    }

    async createScreenShareVideoTrack() {
        console.log('createScreenShareVideoTrack');
    }

    async createLocalAudioTrack() {
        this.localAudioTrack = await createLocalAudioTrack(this.deviceIds.micDeviceId ? { deviceId: this.deviceIds.micDeviceId } : null);
        this.deviceIds.micDeviceId = this.localAudioTrack.mediaStreamTrack.getSettings().deviceId;
        this.emit('deviceIdChange', this.deviceIds);
        pollAudioLevel(this.localAudioTrack, (level: number) => {
            this.emit('audioLevelChange', level);
        });
        this.emit('audioActive', true);
    }

    async enterRoom() {
        try {
            this.room = await connect(this.userTwilioToken, {
                name: this.interaction.id,
                audio: false,
                video: false,
                tracks: [
                    ...(this.localVideoTrack ? [this.localVideoTrack] : []),
                    ...(this.localAudioTrack ? [this.localAudioTrack] : []),
                    ...(this.screenTrack ? [this.screenTrack] : []),
                ],
            });
            this.room.on('participantConnected', (participant) => this.participantConnected(participant));
            this.room.on('participantDisconnected', (participant) => this.participantDisconnected(participant));
            this.room.on('disconnected', (room) => this.disconnected(room));
            this.room.on('recordingStarted', () => this.emit('recordingStatusChange', true));
            this.room.on('recordingStopped', () => this.emit('recordingStatusChange', false));
            this.room.participants.forEach((participant) => this.participantConnected(participant));
            this.connected = true;
            this.emit('connected', this.connected);
            this.emit('recordingStatusChange', this.room.isRecording);
            this.handleError(null);
        } catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    participantConnected(participant: RemoteParticipant) {
        console.log('participantConnected', participant);
        this.createParticipantUi(participant);
        this.emitParticipantUis();
        participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
                const track = publication.track;
                if (track) this.trackSubscribed(track, participant);
            }
        });
        participant.on('trackSubscribed', (track) => this.trackSubscribed(track, participant));
        participant.on('trackUnsubscribed', (track) => this.trackUnsubscribed(track));
    }

    participantDisconnected(participant: RemoteParticipant) {
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        participant.tracks.forEach((publication) => this.detachTrack(publication.track));
        this.removeParticipantUi(participant);
        this.emitParticipantUis();
    }

    attachAllRemoteTracks() {
        this.room?.participants.forEach((participant) => {
            participant.tracks.forEach((publication) => {
                if (publication.isSubscribed) {
                    const track = publication.track;
                    if (track) this.trackSubscribed(track, participant);
                }
            });
        });
    }

    getParticipantUi(participant: RemoteParticipant): VideoParticipantUI | undefined {
        return find(this.participantUIs, (o) => o.sid === participant.sid);
    }

    createParticipantUi(participant: RemoteParticipant) {
        let ui = this.getParticipantUi(participant);
        if (!ui) {
            ui = {
                sid: participant.sid,
                identity: participant.identity,
                isAudioEnabled: false,
                isVideoEnabled: false,
            };
            this.participantUIs.push(ui);
        }
        return ui;
    }

    removeParticipantUi(participant: RemoteParticipant) {
        const index = findIndex(this.participantUIs, (o) => o.sid === participant.sid);
        console.log('removeParticipantUi', index);
        if (index !== -1) {
            this.participantUIs.splice(index, 1);
        }
    }

    trackSubscribed(track: RemoteTrack, participant: RemoteParticipant) {
        console.log('trackSubscribed', track);
        this.updateUis();
        requestAnimationFrame(async () => {
            const div = document.getElementById('remote-media-div-' + participant.sid);
            if (!div) return console.log('no "remote-media-div" ID container found');
            if (track.kind === 'video') {
                const screenShareVideoElement = document.querySelector('#screenShare');
                if (!screenShareVideoElement) {
                    const element = track.attach();
                    element.classList.add(...this.getVideoContainerClasses(track.name === 'screenShare'));
                    element.id = track.name;
                    div.appendChild(element);
                    element.addEventListener('click', this.onRemoteVideoClick.bind(this, div));
                    if (element instanceof HTMLVideoElement) {
                        element.addEventListener('loadeddata', () => {
                            this.handleVideoSize(element);
                        });
                    }
                }

                if (track.name === 'screenShare') {
                    // remove face video track when share screen
                    this.room?.participants.forEach((participant) => {
                        participant.tracks.forEach((publication) => {
                            if (publication.trackName !== 'screenShare' && publication.kind === 'video') {
                                const track = publication.track;
                                if (track) this.trackUnsubscribed(track);
                            }
                        });
                    });
                }
            } else if (track.kind === 'audio') {
                const audioElement = track.attach();
                // @ts-ignore
                if (this.deviceIds.speakerDeviceId && audioElement.setSinkId) {
                    // @ts-ignore
                    await audioElement.setSinkId(this.deviceIds.speakerDeviceId);
                }
                div.appendChild(audioElement);
            } else if (track.kind === 'data' && track.name === 'pointer') {
                this.emit('pointerEnabled', true);
                track.on('message', (data) => {
                    if (typeof data === 'string') {
                        const pointerData = JSON.parse(data);
                        const videoWrapper = document.getElementById('local-media-div');
                        this.showOnDotPointer(pointerData, videoWrapper);
                    } else {
                        console.error('data track message is not a string', data);
                    }
                });
            }
            track.on('disabled', () => this.updateUis());
            track.on('enabled', () => this.updateUis());
        });
    }

    trackUnsubscribed(track: RemoteTrack) {
        console.log('trackUnsubscribed', track);
        this.detachTrack(track);
        this.updateUis();
        if (track.name === 'screenShare') {
            this.room?.participants.forEach((participant) => {
                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed && publication.kind === 'video') {
                        const track = publication.track;
                        if (track) this.trackSubscribed(track, participant);
                    }
                });
            });
        } else if (track.kind === 'data' && track.name === 'pointer') {
            this.emit('pointerEnabled', false);
        }
    }

    updateUis() {
        this.room?.participants.forEach((participant) => {
            const ui = this.getParticipantUi(participant);
            if (!ui) return;
            const publications: RemoteTrackPublication[] = [];
            participant.tracks.forEach((publication) => publications.push(publication));
            const audioPublication = find(publications, (publication) => publication.kind === 'audio');
            ui.isAudioEnabled = !!audioPublication?.isTrackEnabled && !!audioPublication?.isSubscribed;
            const videoPublication = find(
                publications,
                (publication) => publication.kind === 'video' && publication.trackName !== 'screenShare',
            );
            ui.isVideoEnabled = !!videoPublication?.isTrackEnabled && !!videoPublication?.isSubscribed;
        });
        this.emitParticipantUis();
    }

    disconnected(room: Room) {
        console.log('Room disconnected');
        this.emit('disconnected');
        // room.localParticipant.tracks.forEach((publication) => this.detachTrack(publication.track));
        // this.localAudioTrack = undefined;
        // this.localVideoTrack = undefined;
        room.participants.forEach((participant) => {
            this.participantDisconnected(participant);
        });
    }

    detachTrack(track?: LocalTrack | RemoteTrack | null) {
        if (!track || track.kind == 'data') return;
        const attachedElements = <HTMLMediaElement[]>track.detach();
        attachedElements.forEach((element) => element.remove());
    }

    async disableLocalAudio() {
        this.room?.localParticipant.audioTracks.forEach((publication) => {
            publication.track.stop();
            publication.unpublish();
        });
        this.localAudioTrack?.stop();
        this.localAudioTrack = undefined;
        this.emit('audioActive', false);
    }

    async enableLocalAudio() {
        await this.createLocalAudioTrack();
        if (this.localAudioTrack) await this.room?.localParticipant.publishTrack(this.localAudioTrack);
        this.emit('audioActive', true);
    }

    async disableLocalVideo() {
        this.room?.localParticipant.videoTracks.forEach((publication) => {
            console.log(publication);
            if (publication.trackName === 'screenShare') return;
            publication.track.stop();
            publication.unpublish();
            this.detachTrack(publication.track);
        });
        this.localVideoTrack?.stop();
        this.detachTrack(this.localVideoTrack);
        this.localVideoTrack = undefined;
        this.emit('videoActive', false);
    }

    async enableLocalVideo() {
        await this.createLocalVideoTrack();
        if (this.localVideoTrack) await this.room?.localParticipant.publishTrack(this.localVideoTrack);
        this.emit('videoActive', true);
    }

    async toggleLocalAudio() {
        if ((this.room && this.room.localParticipant.audioTracks.size > 0) || this.localAudioTrack) {
            await this.disableLocalAudio();
        } else {
            await this.enableLocalAudio();
        }
    }

    async toggleLocalVideo() {
        if (this.localVideoTrack) {
            await this.disableLocalVideo();
        } else {
            await this.enableLocalVideo();
        }
    }

    async shutdown() {
        await this.room?.disconnect();
        this.room = undefined;
        this.connected = false;
        this.emit('connected', this.connected);
    }

    emitParticipantUis() {
        this.emit('participantUIsUpdate', [...this.participantUIs]);
    }

    handleError(error: any) {
        if (error) console.error(error);
        try {
            this.emit('error', error.message ?? error);
        } catch (e) {
            null;
        }
    }

    updateToken(userTwilioToken: string) {
        this.userTwilioToken = userTwilioToken;
    }

    async changeDevice(deviceId: string, kind: MediaDeviceKind) {
        switch (kind) {
            case 'audioinput':
                this.deviceIds.micDeviceId = deviceId;
                if (this.localAudioTrack) {
                    await this.disableLocalAudio();
                    await this.enableLocalAudio();
                } else {
                    this.emit('deviceIdChange', this.deviceIds);
                }
                break;
            case 'audiooutput':
                this.deviceIds.speakerDeviceId = deviceId;
                this.onSpeakerChange();
                break;
            case 'videoinput':
                this.deviceIds.videoDeviceId = deviceId;
                if (this.localVideoTrack) {
                    await this.disableLocalVideo();
                    await this.enableLocalVideo();
                } else {
                    this.emit('deviceIdChange', this.deviceIds);
                }
                break;
        }
    }

    onSpeakerChange() {
        this.emit('deviceIdChange', this.deviceIds);
        console.log(this.deviceIds.speakerDeviceId);

        if (this.room) {
            console.log('onSpeakerChange');
            this.room.participants.forEach((participant) => {
                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed && publication.kind === 'audio') {
                        const track = publication.track;
                        if (track) {
                            this.trackUnsubscribed(track);
                            this.trackSubscribed(track, participant);
                        }
                    }
                });
            });
        }
    }

    onRemoteVideoClick(videoWrapper: HTMLElement, event: MouseEvent<HTMLVideoElement>) {
        if (!this.dataTrack) return;
        // for pointer feature
        const xPercent = Math.round((event.offsetX / event.target.offsetWidth) * 100);
        const yPercent = Math.round((event.offsetY / event.target.offsetHeight) * 100);
        const pointerData = { x: xPercent, y: yPercent };
        this.sendPointerData(pointerData);
        this.showOnDotPointer(pointerData, videoWrapper);
    }

    async shareScreen() {
        try {
            const localContainer = document.getElementById('local-media-div');
            const stream = await navigator.mediaDevices.getDisplayMedia();
            this.screenTrack = new LocalVideoTrack(stream.getTracks()[0], { name: 'screenShare' });

            if (this.screenTrack) {
                if (this.localVideoTrack) this.needReloadLocalVideo = true;
                await this.disableLocalVideo();
                const videoElement = this.screenTrack.attach();
                videoElement.classList.add(...this.getVideoContainerClasses());
                localContainer.appendChild(videoElement);
                await this.room?.localParticipant.publishTrack(this.screenTrack);
                this.emit('screenSharingActive', true);
                this.screenTrack.on('stopped', async (track) => this.onStopSharingScreen(track));
                if (videoElement instanceof HTMLVideoElement) {
                    this.handleVideoSize(videoElement);
                }
            }
        } catch (e) {
            this.emit('screenSharingActive', false);
        }
    }

    async stopShareScreen() {
        this.screenTrack?.stop();
        this.detachTrack(this.screenTrack);
    }

    async onStopSharingScreen(track) {
        await this.room?.localParticipant.unpublishTrack(track);
        this.emit('screenSharingActive', false);
        if (this.needReloadLocalVideo) {
            this.needReloadLocalVideo = false;
            await this.enableLocalVideo();
        }
    }

    async startPointer() {
        console.log('startPointer');
        try {
            this.dataTrack = new LocalDataTrack({ name: 'pointer' });
            if (this.dataTrack) {
                await this.room?.localParticipant.publishTrack(this.dataTrack);
                this.emit('pointerActive', true);
            }
        } catch (e) {
            this.emit('pointerActive', false);
        }
    }

    async stopPointer() {
        console.log('stopPointer');
        if (this.dataTrack) {
            await this.room?.localParticipant.unpublishTrack(this.dataTrack);
            this.dataTrack = undefined;
        }
        this.emit('pointerActive', false);
    }

    sendPointerData(pointerData: PointerData) {
        if (this.dataTrack) {
            // console.log('sendPointerData', pointerData);
            this.dataTrack.send(JSON.stringify(pointerData));
        }
    }

    handleVideoSize(element: HTMLVideoElement) {
        console.log('handleVideoSize');
        const parent = element.parentElement;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width: parentWidth, height: parentHeight } = entry.contentRect;
                const parentRatio = parentWidth / parentHeight;
                const videoWidth = element.videoWidth;
                const videoHeight = element.videoHeight;
                const videoRatio = videoWidth / videoHeight;
                if (parentRatio > videoRatio) {
                    element.style.width = 'auto';
                    element.style.height = '100%';
                } else {
                    element.style.width = '100%';
                    element.style.height = 'auto';
                }
            }
        });
        resizeObserver.observe(parent);
    }

    showOnDotPointer(pointerData: PointerData, videoWrapper: HTMLElement) {
        if (!videoWrapper) throw new Error('no "local-media-div" ID container found');
        const videoElement = videoWrapper.querySelector('video');
        if (!videoElement) throw new Error('no video element found');
        const { x, y } = pointerData;
        const videoWidth = videoElement.offsetWidth;
        const videoHeight = videoElement.offsetHeight;
        const wrapperWidth = videoWrapper.offsetWidth;
        const wrapperHeight = videoWrapper.offsetHeight;
        const videoX = videoWidth * (x / 100);
        const videoY = videoHeight * (y / 100);
        // minus 5 px due to border width
        const xInPixels = (wrapperWidth - videoWidth) / 2 + videoX;
        const yInPixels = (wrapperHeight - videoHeight) / 2 + videoY;

        // create a div
        const div = document.createElement('div');
        div.classList.add('dot-assist');
        div.style.left = xInPixels + 'px';
        div.style.top = yInPixels + 'px';
        videoWrapper.appendChild(div);
        setTimeout(() => {
            div.remove();
        }, 1000);

        const onClick = (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.sendPointerData(pointerData);
            this.showOnDotPointer(pointerData, videoWrapper);
            div.removeEventListener('click', onClick);
            div.remove();
        };
        div.addEventListener('click', onClick, { once: true });
    }
}
