import fr from '@/i18n/langs/fr';
import { createI18n } from 'vue-i18n';

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: { fr },
    warnHtmlMessage: false,
});
