import mitt from 'mitt';

type Events = {
    backdropTap: null;
    newRefreshTokenOk: null;
    newRefreshTokenFail: null;
    resize: null;
    shareMedia: { recordId?: string };
};

export const emitter = mitt<Events>(); // inferred as Emitter<Events>
